<style scoped>
.handler {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<template>
  <div :style="style">
    <v-select
      :value="control.config.selected"
      :items="control.config.options"
      :id="control.uuid"
      :height="control.size.height"
      :background-color="control.style['background-color']"
      :dark="control.config.theme == 'dark'"
      single-line
      hide-details
      outlined
      dense
      @input="input"
    ></v-select>
    <div class="handler" v-show="!isView"></div>
  </div>
</template>

<script>
export default {
  props: { control: Object, isView: Boolean },
  computed: {
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      delete result["background-color"];
      result["margin-top"] = "0";
      return result;
    },
  },
  methods: {
    input(value) {
      this.$emit("propChange", this.control.uuid, "config.selected", value);
    },
  },
};
</script>
